/* 
 * @Author: 曹俊杰 
 * @Date: 2022-10-12 16:05:28
 * @Module: 提现申请审批
 */
 <template>
  <el-dialog title="提现申请审批" :visible.sync="dialogVisible" width="800px" @closed="closed">
    <div style="padding:20px;">
      <el-form size="mini" label-position="top" label-width="120px" :model="form" ref="form">
        <el-row :gutter="30">
          <el-col :span="24">
            <el-form-item label="状态" prop="state">
              <el-select v-model="form.state" placeholder="请选择" style="width:100%;">
                <el-option label="通过" value="APPROVED" />
                <el-option label="拒绝" value="DENIED" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-show="form.state==='DENIED'">
            <el-form-item label="拒绝原因" prop="comments">
              <el-input v-model="form.comments" type="textarea" :autosize="{minRows: 4, maxRows: 12}" placeholder="请输入拒绝原因"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="close" size="mini">取 消</el-button>
      <el-button type="primary" @click="submit" size="mini">确 定</el-button>
    </span>
  </el-dialog>
</template>
 <script>
export default {
  components: {
  },
  data () {

    return {
      type: 'add',
      dialogVisible: false,
      form: {
        state: "APPROVED",
        comments: '',
        applicationUuid: null
      },

    };
  },
  mounted () { },
  methods: {
    // show ({ type = 'add', data }) {
    //   if (type == "revision") {
    //     let form = this.$utils.deepClone(data);
    //     if (form.lawyer) {
    //       form.lawyer = form.lawyer.split(",")
    //     }
    //     this.form = form
    //   }
    //   this.type = type
    //   this.dialogVisible = true
    // },
    show ({ data }) {
      let form = this.$utils.deepClone(data);
      this.form.applicationUuid = form.uuid
      this.dialogVisible = true;
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.putWithdrawApp()
        } else {
          return false
        }
      })
    },
    close () {
      this.dialogVisible = false
    },
    closed () {
      this.form = {
        state: "APPROVED",
        comments: '',
        applicationUuid: null
      }
    },
    async putWithdrawApp () {
      const { status, data, info } = await this.$api.putWithdrawApp(this.form)
      if (status === 200) {
        this.$message({
          type: 'success',
          message: info
        })
        this.$emit('success')
        this.dialogVisible = false;
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }
    },
  },
};
 </script>
 <style lang='scss' scoped>
</style>